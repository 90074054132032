<template>
  <b-card class="mx-auto mt-12 register-body mb-5">
    <b-alert v-if="show === 'ok'" show variant="success">Tack för ditt svar!</b-alert>
    <b-alert v-if="show === 'reject'" show variant="success"
      >Tack! Du kommer inom kort att bli borttagen som medlem</b-alert
    >
    <div v-if="show === 'answer'">
      <h3 class="text-center mt-4 mb-4">Bekräfta ditt medlemskap</h3>

      <b-img
        v-if="
          company !== undefined &&
            company !== null &&
            company.logo !== undefined &&
            company.logo.indexOf('placeholder') === -1
        "
        :src="company.logo"
        fluid
        alt="Responsive image"
      ></b-img>

      <b-alert
        v-if="company !== undefined && company !== null"
        class="ml-6 mr-6 mt-8 mb-4"
        show
        variant="light"
        >Vänligen bekräfta att du {{ iswas }} medlem mellan <strong>{{ period.from }}</strong> och
        <strong>{{ period.to }}</strong> i <strong>{{ company.name }}</strong></b-alert
      >

      <form class="register-form">
        <b-form-input type="text" v-model="form.firstname" class="mb-8" :placeholder="$t('MEMBER.FIRSTNAME')" />
        <b-form-input type="text" v-model="form.lastname" class="mb-8" :placeholder="$t('MEMBER.LASTNAME')" />
        <b-form-input type="text" v-model="form.personnr" class="mb-8" :placeholder="$t('MEMBER.PERSONNR')" />
        <b-form-input type="text" v-model="form.phone" class="mb-8" :placeholder="$t('MEMBER.MOBILE')" />
        <b-form-input type="email" v-model="form.email" class="mb-8" :placeholder="$t('MEMBER.EMAIL')" />

        <div class="d-flex align-items-center justify-content-between mb-8">
          <b-button class="btn-register" variant="primary" @click="onSubmit"
            >Bekräfta medlemskap</b-button
          >
          <b-button class="btn-register" variant="danger" @click="onReject"
            >Jag är ej medlem</b-button
          >
        </div>
      </form>
    </div>
  </b-card>
</template>

<style lang="scss" scoped>
.register-body {
  max-width: 500px;
  width: 100%;
  .register-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
  .warning-message {
    padding: 30px 20px;
  }
}
</style>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'register-member-no-account',
  data() {
    return {

      member_stickprov_id: null,

      // Remove this dummy login info
      form: {
        email: '',
        password: '',
        company_id: '',
        firstname: '',
        lastname: '',
        phone: '',
        personnr: '',
        member_id: '',
        stickprov_id: ''
      },
      iswas: 'var',
      period: null,
      showWarning: false,
      showForm: false,
      spurl: null,
      company: null,
      show: 'answer'
    };
  },
  mixins: [ toasts ],
  mounted() {
    this.spurl = this.$route.params.id;

    this.loadClient();
  },
  methods: {
    reject() {
      axios
        .put(`/member_stickprov/answer/${this.member_stickprov_id}`, {
          answer: false,
          member_id: this.form.member_id,
          stickprov_id: this.form.stickprov_id,
          token: this.$route.params.token
        })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), 'Svaret registrerat');
            this.show = 'reject';
          } else {
            this.toastr('danger', 'Error', 'Något gick fel');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skicka svar');
        });
    },
    confirm() {
      axios
        .put(`/member_stickprov/answer/${this.member_stickprov_id}`, {
          answer: true,
          member_id: this.form.member_id,
          stickprov_id: this.form.stickprov_id,
          token: this.$route.params.token
        })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), 'Ditt medlemskap är nu bekräftat');
            this.show = 'ok';
          } else {
            this.toastr('danger', 'Error', 'Något gick fel');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skicka svar');
        });
    },
    loadClient() {
      axios
        .get(`/member/spurl/${this.spurl}/token/${this.$route.params.token}`)
        .then(res => {
          this.form = res.data;
          this.period = res.data.stickprov.period;
          this.company = res.data.stickprov.company;
          this.form.firstname = res.data.member.firstname;
          this.form.lastname = res.data.member.lastname;
          this.form.email = res.data.member.email;
          this.form.personnr = res.data.member.personnr;
          this.form.phone = res.data.member.phone;
          this.member_stickprov_id = res.data.id;

          const today = dayjs().format('YYYY-MM-DD');
          //const today = '2020-04-02';

          if (this.period.from <= today && this.period.to >= today) {
            this.iswas = 'är';
          } else {
            this.iswas = 'var';
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hitta medlemsdata');
        });
    },
    onSubmit() {
      this.confirm();
    },
    onReject() {
      this.reject();
    },
    
  }
};
</script>
